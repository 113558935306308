
.gimmickContent {
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .text {

  }
  
  .price {
    font-weight: bold;
  }
}